import React from "react"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Layout, NavigationMenu, NavigationPane, Seo } from "../components"

export default function Diensten({ data }) {
  var title = "Producten"
  var gehooroplossingenMenu = getImage(data.gehooroplossingenMenu)
  var audiometrieMenu = getImage(data.audiometrieMenu)

  return (
    <Layout site="audio" page={`/diensten`}>
      <Seo title={title} shop="audio" />
      <NavigationMenu>
        <Link to="/diensten/gehooroplossingen">
          <NavigationPane image={gehooroplossingenMenu}>
            Gehooroplossingen
          </NavigationPane>
        </Link>
        <Link to="/diensten/audiometrie">
          <NavigationPane image={audiometrieMenu}>Audiometrie</NavigationPane>
        </Link>
      </NavigationMenu>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    audiometrieMenu: file(name: { eq: "audiometrie-menu" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 960)
      }
    }
    gehooroplossingenMenu: file(name: { eq: "gehooroplossingen-menu" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 960)
      }
    }
  }
`
